<template>
  <div class="container" v-if="detail.batchCode">
    <van-nav-bar fixed title="任务详情" left-arrow @click-left="onClickLeft" />

    <van-cell-group inset style="margin-top: 15px">
      <div class="terminalName">{{ detail.posName }}</div>
      <div class="box">
        <div class="boxItem">
          <div class="label">发布：</div>
          <div class="value">{{ detail.publishTime }}</div>
        </div>
      </div>
      <div class="box">
        <div class="boxItem">
          <div class="label">性别要求：</div>
          <div class="value">{{ sex }}</div>
        </div>
        <div class="boxItem">
          <div class="label">任务有效时间：</div>
          <div class="value">{{ detail.requirementsStart }} - {{ detail.requirementsEnd }}</div>
        </div>
        <div class="boxItem">
          <div class="label">任务委托方：</div>
          <div class="value">{{ detail.supplierName }}</div>
        </div>
      </div>
      <div class="box" style="--labelWidth: 90px">
        <div class="boxItem">
          <div class="label">任务说明：</div>
          <div class="value">{{ detail.posDetail }}</div>
        </div>
        <div class="boxItem">
          <div class="label">任务要求：</div>
          <div class="value">{{ detail.requirements }}</div>
        </div>
        <div class="boxItem">
          <div class="label">达成目标：</div>
          <div class="value">{{ detail.reachTarget }}</div>
        </div>
        <div class="boxItem">
          <div class="label">服务费说明：</div>
          <div class="value"><span class="special" @click="showDialog = true">点击查看服务费说明</span></div>
        </div>
        <div class="boxItem">
          <div class="label">任务福利：</div>
          <div class="value">{{ detail.salaryDetail }}</div>
        </div>
        <div class="boxItem">
          <div class="label">其他说明：</div>
          <div class="value" style="font-size: 12px; color: #999">以上结算费用和结算规则均不包含10%平台服务费</div>
        </div>
      </div>
    </van-cell-group>

    <van-cell-group inset style="margin-top: 15px">
      <div class="title-store">门店信息</div>
      <div class="box">
        <div class="boxItem">
          <div class="label">门店名称：</div>
          <div class="value">{{ detail.storeName }}</div>
        </div>
        <div class="boxItem">
          <div class="label">门店地址：</div>
          <div class="value">
            <div>{{ detail.storeAddress }}</div>
            <!-- <div style="text-align: right">{{ detail.distance }}</div> -->
          </div>
        </div>
      </div>
      <div class="box flexBox">
        <div class="boxItem">
          <div class="label">联系人姓名：</div>
          <div class="value">{{ detail.moName }}</div>
        </div>
        <div class="boxItem">
          <div class="label">联系人电话：</div>
          <div class="value">{{ detail.moTel }}</div>
        </div>
      </div>
    </van-cell-group>

    <van-overlay :show="showDialog" :lock-scroll="false">
      <div class="dialog-wrapper" @click="showDialog = false">
        <div class="block" @click.stop>
          <van-icon class="close-icon" name="cross" @click="showDialog = false" />
          <div class="dialog-title">任务详细说明</div>
          <div class="tips">当天任务中的“任务结束”必须完成，才能进入结算。</div>

          <div class="scroll-view">
            <div v-for="(item, index) in detail.tasks" :key="index" class="dialog-content">
              <div class="text">{{ item.taskName }}模块包含：</div>
              <div class="text" v-for="(subItem, subIndex) in item.subList" :key="subIndex">
                <div class="index">{{ subIndex + 1 }}、</div>
                <div class="text-content">
                  <p>
                    {{ subItem.subTaskName }}任务为<span class="strong">{{ subItem.price }}元</span>/次
                  </p>
                  <p>{{ subItem.performTime }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { taskDetail } from '@/api/charge'

export default {
  data() {
    return {
      showDialog: false,
      detail: {}
    }
  },
  created() {
    this.getData()
  },
  computed: {
    sex() {
      const sexMap = {
        0: '不限',
        1: '男',
        2: '女'
      }
      return sexMap[this.detail.sex]
    }
  },
  methods: {
    onClickLeft() {
      location.href = 'javascript:history.back();'
      // Toast("返回");
    },
    getData() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      })
      const { projectCode, terminalCode, taskBatchCode } = this.$route.query
      taskDetail({ projectCode, terminalCode, taskBatchCode: [taskBatchCode] })
        .then(({ data: res }) => {
          Toast.clear()
          if (res.success) {
            this.detail = res.data
          }
        })
        .catch((err) => {
          Toast.clear()
          console.log('err: ', err)
        })
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  padding: 44px 0 20px;
}
.flexBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.terminalName {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  padding: 16px;
}

.title-store {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  padding: 16px;
  display: flex;
  align-items: center;
  &::before {
    content: '';
    width: 2px;
    height: 16px;
    background-color: #0099ff;
    display: block;
    margin-right: 10px;
  }
}

.box {
  --labelWidth: auto;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;

  &::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 16px;
    bottom: 0;
    left: 16px;
    border-bottom: 1px solid #ebedf0;
    transform: scaleY(0.5);
  }

  .boxItem {
    display: flex;
    padding: 4px 0;
    .label {
      color: #999;
      flex-shrink: 0;
      width: var(--labelWidth);
    }
    .value {
      flex: 1;
    }

    .special {
      color: #0099ff;
    }
  }
}

.dialog-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #333;
  font-size: 14px;
  line-height: 24px;

  .block {
    margin: 0 20px;
    background-color: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 20px;
    position: relative;
  }

  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
  }

  .dialog-title {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 10px 0;
  }

  .scroll-view {
    max-height: 50vh;
    overflow-y: auto;
  }

  .dialog-content {
    margin-bottom: 15px;
  }

  .tips {
    color: #ff3c3c;
    margin-bottom: 10px;
  }

  .text {
    display: flex;
    p {
      margin: 0;
      padding: 0;
    }
    .label {
      width: 30px;
      flex-shrink: 0;
    }
    .text-content {
      flex: 1;
      .strong {
        font-weight: bold;
      }
    }
  }
}
</style>
